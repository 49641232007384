export default {
  product: 'Продукт',
  pricing: 'Тарифы',
  download: 'Скачать',
  blog: 'Блог',
  referral: 'Реферальная программа',
  partners: 'Партнёры',
  reference: 'Ресурсы',
  support: 'Поддержка',
  api: 'API',
  help: 'FAQ',
  terms: 'Условия использования',
  agree: 'Пользовательское соглашение',
  privacy: 'Политика конфиденциальности',
  legal: 'Соглашения',
}