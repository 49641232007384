export default {
  promo: {
    title: 'Використати промокод',
    text: 'Використайте промокод для отримання знижки на обраний вами тариф',
    input: 'Промокод',
    button: 'Застосувати',
  },
  deposit: {
    title: 'Поповнити баланс',
    method: 'Метод поповнення',
    amount: 'Сума в $',
    button: 'Підтвердити',
    options: {
      crypto: 'Crypto',
      card: 'Бансківська карта',
      balance: 'Баланс',
    },
  },
  subscription: {
    title: 'Підписка',
    method: 'Вибрати метод',
    total: 'Загальна ціна',
    free: 'Безкоштовно',
    week: '7 днів',
    button: 'Підтвердити',
    params: {
      user: 'Користувач',
      subcription: 'Підписка',
      period: 'Період',
      month: 'Ціна за місяць',
      price: '/міс',
      chargeback: 'Зворотний платіж на вашу картку',
      cashback: 'Кешбек на баланс аккаунта'
    },
    payment: {
      balance: 'Баланс',
      card: 'Картка',
    }
  }
}