export default {
  index: {
    title: 'Браузер-антидетект для мультиаккаунта - Антидетект AQUM',
    description: 'AQUM — это безопасный браузер, полностью ориентированный на защиту от обнаружения для вашей безопасности и конфиденциальности. Присоединяйтесь сегодня!',
    ogTitle: 'Браузер-антидетект для мультиаккаунта - Антидетект AQUM',
    ogDescription: 'AQUM — это безопасный браузер, полностью ориентированный на защиту от обнаружения для вашей безопасности и конфиденциальности. Присоединяйтесь сегодня!',
    twitterTitle: 'Браузер-антидетект для мультиаккаунта - Антидетект AQUM',
    twitterDescription: 'AQUM — это безопасный браузер, полностью ориентированный на защиту от обнаружения для вашей безопасности и конфиденциальности. Присоединяйтесь сегодня!',
  },
  prices: {
    title: 'Подписки и цены',
  },
  referral: {
    title: 'Реферальная программа',
  },
  downloads: {
    title: 'Скачать AQUM',
  },
  partners: {
    title: 'AQUM Partners',
  },
  blog: {
    title: 'Блог AQUM',
  },
  faq: {
    title: 'Часто задаваемые вопросы',
  },
  documents: {
    terms: {
      title: 'Условия использования'
    },
    ua: {
      title: 'Пользовательское соглашение'
    },
    privacy: {
      title: 'Политика конфиденциальности'
    },
  },
  auth: {
    signup: {
      title: 'Регистрация'
    },
    signin: {
      title: 'Авторизация'
    }
  },
  account: {
    title: 'Аккаунт',
  }

}