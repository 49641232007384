export default {
  button: 'Главная',
  404: 'К сожалению, нам не удалось найти эту страницу',
  500: 'Попробуйте перезагрузить страницу, чтобы повторно подключиться к сервису',
  schema: {
    pass: 'Пароль должен иметь длину не менее 8 символов',
    number: 'Пароль должен содержать цифру',
    upper: 'Пароль должен содержать заглавную букву',
    required: 'Требуется пароль',
    match: 'Пароли должны совпадать',
    email: {
      req: 'Необходим адрес электронной почты',
      invalid: 'Неверный адрес электронной почты',
    },
    code: 'Не может быть пустым',
  },
  changePass: {
    success: 'Пароль был успешно изменен',
    incorrect: 'Неправильный пароль',
    lifetime: 'Срок действия ссылки истек. Попробуйте еще раз',
  },
  confirmAcc: {
    success: 'Ссылка для подтверждения была отправлена ​​на электронную почту',
    verif: 'Не удалось отправить ссылку для подтверждения. Попробуйте еще раз',
  },
  resetPass: {
    success: 'Информация о сбросе пароля была отправлена ​​на указанный адрес',
    noUser: 'Пользователь с таким адресом электронной почты не найден',
  },
  signIn: {
    incorrect: 'Неверное имя пользователя или пароль',
  },
  signUp: {
    error: 'Не удалось зарегистрироваться. Попробуйте еще раз',
  },
  download: {
    error: 'Не удалось получить актуальную ссылку для загрузки приложения Windows',
  },
  promocode: {
    promotedBalance: 'На ваш баланс было зачислено {value}$',
    promotedSubscription: '{plan} подписка активирована',
    makeDiscount: 'Стоимость плана {plan} была снижена на {value}{type}',
    makeTotalDiscount: 'Стоимость всех планов была снижена на {value}{type}',
    extendExpirationFixed: 'Ваша подписка была продлена на {value}д.',
    extendExpirationPercent: 'Ваша подписка была продлена на {value}%',
    success: 'Промо-код был успешно применен к вашей учетной записи.',
    fail: 'Неверный или просроченный промокод',
  },
  paymentMethod: 'Выберите метод оплаты',
};
