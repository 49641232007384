export default {
  index: {
    title: 'Antidetect browser for multi-accounting - AQUM Antidetect',
    description: 'AQUM is a security and fully focused on an antidetect browser for your safety and privacy. Join today!',
    ogTitle: 'Antidetect browser for multi-accounting - AQUM Antidetect',
    ogDescription: 'AQUM is a security and fully focused on an antidetect browser for your safety and privacy. Join today!',
    twitterTitle: 'Antidetect browser for multi-accounting - AQUM Antidetect',
    twitterDescription: 'AQUM is a security and fully focused on an antidetect browser for your safety and privacy. Join today!',
  },
  prices: {
    title: 'Subscriptions and Prices',
  },
  referral: {
    title: 'Referral Program',
  },
  downloads: {
    title: 'Download AQUM',
  },
  partners: {
    title: 'AQUM Partners',
  },
  blog: {
    title: 'AQUM Blog',
  },
  faq: {
    title: 'Frequently Asked Question',
  },
  documents: {
    terms: {
      title: 'Terms of Service'
    },
    ua: {
      title: 'User Agreement'
    },
    privacy: {
      title: 'Privacy Policy'
    },
  },
  auth: {
    signup: {
      title: 'Sign Up'
    },
    signin: {
      title: 'Sign In'
    }
  },
  account: {
    title: 'Account',
  }

}