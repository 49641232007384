export default {
  balance: {
    text: 'Баланс',
    current: 'Текущий баланс',
    promo: 'Промокод',
    deposit: 'Пополнить',
  },
  settings: {
    general: 'Аккаунт',
    password: 'Пароль',
    activeSubs: 'Активная подписка',
    subsStatus: 'Статус подписки',
    subsExpire: 'Заканчивается через {days} дня(дней)',
    expired: 'Срок действия подписки истек',
    referral: 'Реферальный код',
    referralBtn: 'Скопировать',
    buttonText: 'Изменить',
    exit: 'Выход',
    button: 'Выйти',
  },
  changePlan: 'Выбрать план',
  empty: {
    title: 'У вас нет подписки',
    caption: 'Пожалуйста, перейдите в раздел с ценами и выберите подходящее вам предложение!',
    button: 'Выбрать план',
  }
}