export default {
  product: 'Product',
  pricing: 'Pricing',
  download: 'Download',
  blog: 'Blog',
  referral: 'Referral Program',
  partners: 'Partners',
  reference: 'Reference',
  support: 'Support',
  api: 'API',
  help: 'Help Center',
  terms: 'Terms of Service',
  agree: 'User Agreement',
  privacy: 'Privacy Policy',
  legal: 'Legal',
}