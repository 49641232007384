import fields from './fields';
import errors from './errors';
import success from './success';
import home from './home';
import subscribe from './subscribe';
import footerNav from './footerNavigation';
import footer from './footer';
import subscription from './subscription';
import prices from './prices';
import promo from './promo';
import paymentTypes from './paymentTypes';
import download from './download';
import faq from './faq';
import auth from './auth';
import blog from './blog';
import referral from './referral';
import partners from './partners';
import account from './account';
import header from './header';
import navigation from './navigation';
import modals from './modals';
import payment from './payment';
import meta from './meta';
import reviews from './reviews';

export default {
  fields,
  errors,
  success,
  home,
  subscribe,
  footerNav,
  footer,
  subscription,
  prices,
  promo,
  paymentTypes,
  download,
  faq,
  auth,
  blog,
  referral,
  partners,
  account,
  header,
  navigation,
  modals,
  payment,
  meta,
  reviews,
};
