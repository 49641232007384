export default {
  product: 'Продукт',
  pricing: 'Тарифи',
  download: 'Завантажити',
  blog: 'Блог',
  referral: 'Реферальна програма',
  partners: 'Партнери',
  reference: 'Ресурси',
  support: 'Підтримка',
  api: 'API',
  help: 'FAQ',
  terms: 'Умови використання',
  agree: 'Угода користувача',
  privacy: 'Політика конфіденційності',
  legal: 'Юридична інформація'
}