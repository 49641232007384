export default {
  success: {
    title: 'Успішно!',
    caption: 'Ваш тариф успішно активовано. Перейдіть у браузер AQUM, щоб скористатися можливостями свого облікового запису!',
    captionDeposit: 'Поповнення балансу пройшло успішно',
    button: 'Готово',
  },
  error: {
    title: 'Помилка!',
    caption: 'Не вдалося здійснити оплату. Спробуйте ще раз або зв\'яжіться з підтримкою',
    button: 'Закрити',
  },
}