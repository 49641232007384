export default {
  index: {
    title: 'Браузер Antidetect для кількох облікових записів - AQUM Antidetect',
    description: 'AQUM — це безпека, яка повністю зосереджена на антидетекторному браузері для вашої безпеки та конфіденційності. Приєднуйтесь сьогодні!',
    ogTitle: 'Браузер Antidetect для кількох облікових записів – AQUM Antidetect',
    ogDescription: 'AQUM — це безпека, яка повністю зосереджена на антидетекторному браузері для вашої безпеки та конфіденційності. Приєднуйтесь сьогодні!',
    twitterTitle: 'Браузер Antidetect для кількох облікових записів - AQUM Antidetect',
    twitterDescription: 'AQUM — це безпека, яка повністю зосереджена на антидетекторному браузері для вашої безпеки та конфіденційності. Приєднуйтесь сьогодні!',
  },
  prices: {
    title: 'Підписки та ціни',
  },
  referral: {
    title: 'Реферальна програма',
  },
  downloads: {
    title: 'Завантажити AQUM',
  },
  partners: {
    title: 'Партнери AQUM',
  },
  blog: {
    title: 'Блог AQUM',
  },
  faq: {
    title: 'Часті запитання',
  },
  documents: {
    terms: {
      title: 'Умови використання'
    },
    ua: {
      title: 'Угода користувача'
    },
    privacy: {
      title: 'Політика конфіденційності'
    },
  },
  auth: {
    signup: {
      title: 'Реєстрація'
    },
    signin: {
      title: 'Авторизація'
    }
  },
  account: {
    title: 'Акаунт',
  }

}