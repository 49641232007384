export default {
  balance: {
    text: 'Баланс',
    current: 'Поточний баланс',
    promo: 'Промокод',
    deposit: 'Поповнити',
  },
  settings: {
    general: 'Акаунт',
    password: 'Пароль',
    activeSubs: 'Активна підписка',
    subsStatus: 'Статус підписки',
    subsExpire: 'Закінчуеться через {days} дні(днів)',
    expired: 'Термін дії підписки закінчився',
    referral: 'Реферальний код',
    buttonText: 'Редагувати',
    referralBtn: 'Копіювати',
    exit: 'Виxід',
    button: 'Вийти',
  },
  changePlan: 'Обрати план',
  empty: {
    title: 'У вас немає підписки',
    caption: 'Перейдіть, будь ласка, в розділ з цінами та оберіть ту пропозицію, яка Вам підходить!',
    button: 'Обрати план',
  }
}