export default {
  title: 'Сделайте свою работу быстрой и безопасной с помощью AQUM Browser',
  text: 'Хотите быть в курсе всех новостей, скидок, акций? Подпишитесь на нашу рассылку и получайте самую свежую информацию первым',
  button: 'Подписаться!',
  slider: {
    1: 'Изучите браузер AQUM',
    2: 'Изучите мобильное приложение AQUM',
    3: 'Присоединяйтесь к экосистеме AQUM сегодня',
  },
  follow: 'Следите за нами в социальных сетях',
}