import en from './en/index.js';
import ru from './ru/index.js';
import ua from './ua/index.js';

export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en,
    ru,
    ua,
  }
}));
