export default {
  title: 'Поширені запитання',
  text: 'Недостатньо інформації? Тут ви можете знайти відповіді на питання, які вас цікавлять ',
  button: 'Підтримка',
  nav: {
    general: 'Загальні',
    profile: 'Профіль',
    team: 'Команда',
    api: 'API',
  },
  questions: {
    general: {
      1: {
        question: "Як почати роботу?",
        answer: "Після створення облікового запису ви отримаєте посилання активації на свою електронну адресу.  Для того, щоб отримати доступ до функцій браузера AQUM, вам необхідно поповнити баланс",
      },
      2: {
        question: "Як поповнити рахунок?",
        answer: "Ми приймаємо такі способи оплати: Дебетові та кредитні картки (Visa, MasterCard), Apple Pay, Google Pay, криптовалюти (BTC, LTC, TRC, ETH).",
      },
      3: {
        question: "Як розраховується курс обміну валют?",
        answer: "Якщо ви обираєте оплату криптовалютою, комісія не стягується, курс обміну надається в режимі реального часу через платіжну платформу. При оплаті іншими валютами комісію знімає платіжна система, в цьому випадку все залежить від їхнього обмінного курсу.",
      },
      4: {
        question: "Як змінити тариф?",
        answer: "Тариф може бути змінений, навіть якщо він активний, але для цього необхідно доплатити різницю при переході на дорожчий план.\nДля цього перейдіть у Налаштування -> натисніть Змінити підписку.\n Якщо ви вирішили перейти на дорожчий тарифний план, виконайте цю операцію через технічну підтримку. Якщо на вашому балансі є необхідна сума, зв'яжіться зі службою підтримки та вкажіть свій e-mail із бажаним тарифом.\n Перехід на новий тарифний план можливий тільки після закінчення терміну дії вашої поточної підписки. Поповніть баланс і виберіть найбільш підходящий варіант. Усі наявні ресурси, які перевищують обраний тип підписки, будуть видалені.",
      },
    },
    profile: {
      1: {
        question: "Як перенести профіль?",
        answer: "Виберіть профілі в таблиці, потім у Меню виберіть пункт Перенести профілі. За необхідності ви також можете перенести їх разом із проксі.",
      },
      2: {
        question: "Як видалити профіль? ",
        answer: "Натисніть на три крапки поруч із профілем і виберіть у випадаючому меню пункт Видалити. Натисніть кнопку Підтвердити у спливаючому вікні, щоб зберегти зміни.",
      },
      3: {
        question: "Як додати файли cookie?",
        answer: "AQUM дозволяє завантажити документ у форматі .txt або .json. Перетягніть файл на екран Імпорт файлів cookie або вкажіть посилання через кому / з нового рядка.",
      },
    },
    team: {
      1: {
        question: "Як додати члена команди?",
        answer: "Виберіть Додати користувача в правому меню на екрані в меню Команди.",
      },
      2: {
        question: "Як відредагувати команду?",
        answer: "Перейдіть на вкладку Команда на головній сторінці та виберіть команду, в якій ви хочете внести зміни. Тут ви можете завантажити або змінити логотип, назву та кількість користувачів.",
      },
      3: {
        question: "Що я можу побачити в історії профіля?",
        answer: "В історії профілю відображаються останні дії профілю, такі як: Створення профілю\n Експорт профілю\n Запуск профілю\n Перенесення профілю\n Видалення профілю\n Зміна проксі та Cookies",
      },

    },
    api: {
      1: {
        question: "Що я можу зробити за допомогою API?",
        answer: "API дає змогу автоматизувати взаємодію, використовуючи всі дані та можливості через спеціальний документований інтерфейс. Створюючи профілі, пов'яжіть їх із необхідними налаштуваннями (наприклад: proxy і cookies). API зараз доступна з тарифу Team і вище.\n Токен API доступний тільки для майстер-акаунта у вкладці \"Налаштування\" (токен не відображається для членів команди).",
      }
    }
  }
}