export default {
  promo: {
    title: 'Referral Program',
    text: 'Invite your friends to register on AQUM and get up to 50% of their fees. More friends – more benefits!',
    button: 'Get Referral Link',
  },
  flow: {
    caption: "How it's work",
    title: 'AQUM referral',
    tiles: {
      1: {
        title: 'Create Account',
        caption: 'Create your account in AQUM Browser to enjoy all its benefits and get a referral link',
      },
      2: {
        title: 'Share with Your Friends',
        caption: 'Send a link to your friends so that they can also register on AQUM and you can get bonuses for it',
      },
      3: {
        title: 'Get Bonuses',
        caption: 'When referrals start using subscription, a percentage of their fees will be credited to your balance once a month',
      },
    }
  },
  benefits: {
    upperTitle: 'Benefits',
    title: 'Benefits of AQUM Referral program',
    caption: 'Earn together with AQUM - share your link with friends, colleagues and get bonuses for each new registration. Track your rewards in your personal cabinet. Start earning today!',
    tiles: {
      1: {
        title: 'High Rate',
        caption: '15% of each payment from your referrals',
      },
      2: {
        title: 'Tracking',
        caption: 'Track referrals in your personal cabinet',
      },
      3: {
        title: 'Guarantee',
        caption: 'Guaranteed timely payments',
      },
    }
  }
}