export default {
  title: 'Завантажити AQUM',
  caption: 'Завантажуючи AQUM, ви приймаєте Умови використання, Угоду користувача, Політику конфіденційності.',
  win: {
    title: 'Windows',
    caption: "<p>- Windows 10 і вище</p> <p>- 2 ГБ оперативної пам'яті для 64-розрядної системи</p> <p>- Не менше 2 ГБ вільного дискового простору</p>",
    button: 'Завантажити 64 bit',
  },
  mac: {
    title: 'MacOS',
    caption: '<p>- MacOS Big Sur 11.0 і вище</p> <p>- Підтримка Intel і Apple Silicon</p>',
    button: 'Завантажити (Intel)',
    buttonSecondary: 'Завантажити (Apple Silicon)',
  },
  Android: {
    title: 'Android',
    version: '',
    caption: 'Android 8 і вище',
    button: 'Завантажити',
  },
  iOS: {
    title: 'iOS',
    version: '',
    caption: 'iOS 16 і вище',
    button: 'Завантажити',
  },
};
